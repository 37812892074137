<template>
  <div
    class="bg-white custom-card d-flex flex-column justify-content-start gap-20 w-100 shadow"
  >
    <div class="title d-flex gap-1">
      {{ cardTitle }}
      <span
        v-if="info"
        v-b-popover.hover.top="info"
      >
        <img
          src="@/assets/images/icons/info-circle.svg"
          alt="Info"
        >
      </span>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    cardTitle: String,
    info: String,
  },
}
</script>
