const informasiSaldo = [
  {
    key: 'date',
    label: 'Tanggal',
    thClass: 'text-capitalize h-text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'withdrawal_destination',
    label: 'Tujuan Penarikan',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'status',
    label: 'Status',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'amount',
    label: 'Jumlah Penarikan',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'detail',
    label: 'Rincian',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
]

const detailSaldo = [
  {
    key: 'date',
    label: 'Tanggal',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'jenis_transaksi',
    label: 'Jenis Transaksi',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'jumlah',
    label: 'Jumlah',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'saldo',
    label: 'Saldo',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'detail',
    label: 'Rincian',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
]

const detailWithdrawal = [
  {
    key: 'date',
    label: 'Tanggal',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'jenis_transaksi',
    label: 'Jenis Transaksi',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'jumlah',
    label: 'Jumlah',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'saldo',
    label: 'Saldo',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'detail',
    label: 'Rincian',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
]

const outboundCosts = [
  {
    key: 'tanggal_pickup',
    label: 'Tanggal Pengajuan Pickup',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'tanggal_order',
    label: 'Tanggal Order',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'produk',
    label: 'Produk',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'no_resi',
    label: 'No Resi',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'biaya_fulfillment',
    label: 'Biaya Fulfillment',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
]

export {
  informasiSaldo,
  detailSaldo,
  detailWithdrawal,
  outboundCosts,
}
